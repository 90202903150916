/* switchTransition.css */
.transition-wrapper {
  width: 100%;
  height: 100%;
}

.fade-forward-enter {
  opacity: 0;
  transform: translateX(100%);
}
.fade-forward-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms, transform 200ms;
}
.fade-forward-exit {
  opacity: 1;
  transform: translateX(0);
}
.fade-forward-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 200ms;
}

.fade-backward-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-backward-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms, transform 200ms;
}
.fade-backward-exit {
  opacity: 1;
  transform: translateX(0);
}
.fade-backward-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 200ms, transform 200ms;
}
