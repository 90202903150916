@font-face {
    font-family: 'Sora';
    src: url('./Sora-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Sora';
    src: url('./Sora-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Sora';
    src: url('./Sora-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Sora';
    src: url('./Sora-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Sora';
    src: url('./Sora-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Sora';
    src: url('./Sora-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Sora';
    src: url('./Sora-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Sora';
    src: url('./Sora-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
