.form{
    width: 100%;
}



.form .applePayButton,
.form .cardButton{
    margin-top: 2em;
    width: 100%;
    padding: 14px 16px 14px 16px;
    gap: 8px;
    border-radius: 16px;
    background: var(--primary-linear-background);
    font: var(--C1);
    box-shadow: var(--CTA-Sh);
    text-align: center;
    color: #ffffff;
}

.form .applePayButton{
    background: #000;
    margin-top: .5em;
    box-shadow: none !important
}

.form .submiting{
    background: var(--Neutral-300);
}

