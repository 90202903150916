.button {
    background-color: #fff;
    width: 100%;
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.modalContent h5{
    font: var(--H5-M);
    text-align: left;
    color: var(--Neutral-500, #323E48);

}

.methods{
    display: flex;
    flex-direction: column;
    border-top: 0.5px solid var(--Neutral-100, #E4E8EC);
    padding: 16px 0px 8px 0px;
    gap: 16px;
    margin: 16px 0;
    
}


.radioLabel {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
}

.radioInput {
    display: none;
}

.customRadio {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1.5px solid #ccc;
    position: relative;
}

.checked {
    border-color: var(--primary-color);
}

.checked::after {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--primary-linear-background);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.labelText {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-500, #323E48);
    display: flex;
    align-items: center;
}
.labelText img {
    margin-right: 16px;
}