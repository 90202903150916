.container{
    padding-bottom: 80px;
}

.header {
  background: var(--primary-header-color);
  width: 100%;
  height: 128px;
  top: 0;
}

.upper {
    width: 100%;
    padding: 16px;
  }

.backArrow {
    cursor: pointer;
    background-color: #fff;
    border-radius: 100px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header .title {
  gap: 8px;
  padding: 0 16px 24px 16px;
}

.header .title h1 {
  font: var(--H1);
  text-align: left;
  color: var(--Neutral-500, #323e48);
}

.ordersItems{
    padding: 16px;
    gap: 10px;
    position: relative;
    top: -8px;
    background-color: #fff;
    border-radius: 16px;
}

.orderItem{
    margin-bottom: 10px;
    border-radius: 16px;
    border: 1px solid var(--Neutral-100, #E4E8EC);
    cursor: pointer;
}

.imageContainer{
    padding: 8px;

}
.orderItem img{
    width: 64px;
    height: 64px;
    border-radius: 12px;
}

.data {
    align-self: center;
    flex: 2;
}
.data .title{
    font: var(--B1-R);
    text-align: left;
    color: var(--Neutral-500, #323E48);
    
}

.data .time{
    font: var(--B2-L);
    letter-spacing: 0.01em;
    text-align: left;
    display: inline;
    margin: 0;
    color: var(--Neutral-300, #8B98A4);

}

.status{
    color: #3BAB78;
    font: var(--B2-R);
    text-align: left;
    display: inline;
    margin-right: 2px;
}


.completed{
    color: #323E48;
}

.canceled{
    color: #D83432;
}


.orders .rating {
    padding: 16px;
    gap: 8px;
    background: #ECF5FE;
    border-radius: 0 0 16px 16px;
}

.orders .rating p {
  font: var(--B1-R);
  text-align: left;
  color: var(--Neutral-500, #323E48);
}

.faces{
    margin-top: 8px;
    gap: 16px;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px;
    gap: 8px;
    background: var(--Neutral-50, #F2F5F8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.emptyOrders{
  background: transparent;
}

.footer p{
    font: var(--B2-L);
    letter-spacing: 0.01em;
    text-align: center;
    color: var(--Neutral-300, #8B98A4);

}


.footer .backHome {
    width: 343px;
    padding: 14px 16px 14px 16px;
    gap: 8px;
    border-radius: 16px;
    background: var(--primary-linear-background);
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .noScroll {
    overflow: hidden;
  }
  
  
  .rateModal {
    height: auto;
    gap: 24px;
    border-radius: 8px 8px 0 0;
    padding: 0;
    margin: 0;
    background-color: white;
    position: absolute;
    bottom: -100%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: slide-in 0.4s forwards;
    padding: 24px 16px;
    outline: none;
  }
  
  .exit {
    animation: slide-out 0.4s forwards;
  }
  
  .exitOverlay{
    animation: slide-out 0.4s forwards;
  
  }

  .rateModal .faces p{
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: center;
    color: var(--Neutral-300, #8B98A4);

  }

  .rateModal .faces .rateSelected{
    background: var(--primary-linear-background);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  }

  .rateModal .rating {
    gap: 16px;
padding: 8px 0px 32px 0px;
border-bottom: 1px solid var(--Neutral-100, #E4E8EC)

  }

  .rateModal .rating svg{
    width: 48px;
    height: 48px;
  }
  .rateModal h4{
    font: var(--H4-M);
    text-align: center;
    color: var(--Neutral-500, #323E48);

  }

  .rateModal .comment{
    width: 343px;
    height: 92px;
    padding: 16px;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid var(--Neutral-100, #E4E8EC);
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
  }
  
  .rateModal .submit{
    width: 343px;
    padding: 14px 16px 14px 16px;
    gap: 8px;
    border-radius: 16px;
    background: var(--primary-linear-background);
    font: var(--C1);
    text-align: center;
    color: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  
  @keyframes slide-in {
    from {
      bottom: -100%;
    }
    to {
      bottom: 0;
    }
  }
  
  @keyframes slide-out {
    from {
      bottom: 0;
      opacity: 1;
    }
    to {
      bottom: -100%;
      opacity: 0;
    }
  }