.faq-row-wrapper .faq-body .faq-row .row-title {
    color: var(--Neutral-500, #323E48) !important;
    font: var(--H5-M) !important;
}

.faq-row-wrapper .faq-body .faq-row{
    border-bottom: 2px solid var(--Neutral-100, #E4E8EC);
    padding: 0;
}
.faq-row-wrapper .faq-body .faq-row .row-title{
    padding: 16px !important;
}


.faq-row-wrapper .faq-body .faq-row .row-content{
    padding: 0 16px;
}

.faq-row-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
