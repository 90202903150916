nav{
    width: 100%;
    padding: 16px;
    position: fixed;
  }
  
  .logoContainer{
    flex: 3;
  }
  
  .logo{
    width: 50px;
    height: 50px;
    top: 41px;
    left: 80px;
  }

  .logo img {
    max-height: 50px;
    max-width: 50px;
  }
  
  .logo p{
    font-size: 21px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    color: #fff;
  }
  
  .line{
    width: 14px;
    height: 3px;
    background-color: var(--Neutral-500, #323E48);
    margin: 3px 0 0 0;
  }
  
  .dropdownContainer {
    position: relative;
    display: inline-block;
  }
  
  .dropdownButton {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
  }
  
  
  .dropdownItem {
    padding: 8px 16px;
    cursor: pointer;
    border: none;
    background: none;
    text-align: left;
    width: 150px;
    font-size: 14px;
    color: var(--Neutral-400, #323E48);
  }
  
  .dropdownItem:hover {
    background-color: #f0f0f0;
  }
  