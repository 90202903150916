.menuItems {
  background: var(--Neutral-50, #f2f5f8);
}
.header {
  width: 100%;
  position: relative;
  background: #fff;
}

.header .image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 280px;
}


.backArrow {
  position:absolute;
  top: 16px;
  left: 16px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.article {
  padding: 16px;
  gap: 16px;
  background-color: #fff;
}
.article h2 {
  font: var(--H2);
  text-align: left;
  color: var(--Neutral-500, #323e48);
  margin-bottom: 8px;
}

.article p {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-400, #626b73);
}

.group {
  margin: 4px 0;
  padding: 16px;
  gap: 8px;
  background-color: #fff;
}

.group h5 {
  font: var(--H5-M);
  text-align: left;
  color: var(--Neutral-500, #323e48);
}
.group span {
  font: var(--B1-L);
  text-align: left;
  color: var(--Neutral-300);
  align-self: center;
}

.group .required {
  color: #996000;
}

.group .forcedRequired {
  color: red;
  position: relative;
  margin-left: 15px;
}

.group .forcedRequired::before {
  content: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99944 10.2857C7.92058 10.2857 7.85658 10.3497 7.85715 10.4285C7.85715 10.5074 7.92115 10.5714 8.00001 10.5714C8.07887 10.5714 8.14287 10.5074 8.14287 10.4285C8.14287 10.3497 8.07887 10.2857 7.99944 10.2857" stroke="%23D83432" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M4.94267 13.6093L2.39067 11.0573C2.14067 10.8073 2 10.468 2 10.1147V5.88533C2 5.532 2.14067 5.19267 2.39067 4.94267L4.94267 2.39067C5.19267 2.14067 5.532 2 5.88533 2H10.114C10.4673 2 10.8067 2.14067 11.0567 2.39067L13.6087 4.94267C13.8593 5.19267 14 5.532 14 5.88533V10.114C14 10.4673 13.8593 10.8067 13.6093 11.0567L10.6667 14H5.88533C5.532 14 5.19267 13.8593 4.94267 13.6093Z" stroke="%23D83432" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.00001 7.85714V5" stroke="%23D83432" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  width: 16px;
  margin-right: 4px;
  position: absolute;
  left: -18px;
  top: 0;
}

.groupItem {
  padding: 10px 0;
}
.groupItem .name {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-500, #323e48);
  width: 50%;
}

/* Checkboxes */
.checkboxContainer {
  display: inline-block;
  position: relative;
}

.customCheckbox {
  display: none;
}

.dim,
.checkboxLabel {
  width: 20px; /* Adjust the size as needed */
  height: 20px; /* Adjust the size as needed */
  border-radius: 6px; /* Rounded corners */
  border: 1.5px solid var(--Neutral-200, #bcc4cc);
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: all 0.2s ease;
}

.checkboxLabel:after {
  content: "";
  position: absolute;
  top: 30%;
  left: 27%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="5" height="2" viewBox="0 0 20 8" fill="none"><path d="M1.44141 4.32975L3.60841 6.49675L3.59441 6.48275L8.48341 1.59375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  width: 11px; /* Size of the check mark */
  height: 8px; /* Size of the check mark */
  transition: transform 0.2s ease;
}

.customCheckbox:checked + .checkboxLabel {
  background: var(--primary-linear-background-180);
  border: none;
}

/* RadioButtons */
.radioLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.radioInput {
  display: none;
}

.customRadio {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1.5px solid #ccc;
  position: relative;
}

.checked {
  border-color: var(--primary-color);
}

.checked::after {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--primary-linear-background);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.labelText {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-500, #323e48);
  display: flex;
  align-items: center;
}
.labelText img {
  margin-right: 16px;
}

.dim {
  background: var(--Neutral-50, #f2f5f8);
  color: var(--Neutral-200, #bcc4cc);
}
.dimText {
  color: var(--Neutral-200, #bcc4cc) !important;
}
/* Counter */

.counterDim,
.add .container {
  height: 34px;
  padding: 8px 8px;
  gap: 2px;
  border-radius: 10px;
  border: 1px solid var(--Neutral-100, #e4e8ec);
}

.add .counterDim {
  background: var(--Neutral-50, #f2f5f8);
}

.add .counterDim .plus svg path {
  stroke: var(--Neutral-300, #f2f5f8);
}

.add .counter {
  display: flex;
  border-radius: 16px;
}

.add .counter button:last-child {
  margin-left: 3px;
}

.add .count {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  margin: 0 5px 0 10px;
  background: var(--primary-linear-background);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.additionalNotes {
  background-color: #fff;
  padding: 16px;
  background-color: #fff;
  margin-bottom: 6em;
}

.additionalNotes h5 {
  font: var(--H5-M);
  text-align: left;
  color: var(--Neutral-500, #323e48);
  margin-bottom: 16px;
}

.additionalNotes .serviceDetail input {
  width: 100%;
  gap: 8px;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid var(--Neutral-100, #E4E8EC);
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  outline: none;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e4e8ec;
  padding: 16px;
  gap: 8px;
  background: #ffff;
}

.footer .continue {
  width: 60%;
  padding: 10px 16px;
  gap: 8px;
  border-radius: 16px;
  background: var(--primary-linear-background);
  font: var(--C1);
  box-shadow: var(--CTA-Sh);

  text-align: center;
  color: #ffffff;
}

.footer .continue .priceDiscount{
  font: var(--B2-L);
  line-height: 10px !important;
  letter-spacing: 0.01em;
  text-align: right;
  color: #FFFFFF;
  opacity: 50%;
  text-decoration: line-through;

}
.footer .continue p {
  font: var(--C1);
  text-align: right;
}

.footer .counter {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid var(--Neutral-100, #e4e8ec);
}

.footer .button {
  padding: 18px 14px 18px 14px;
  gap: 2px;
}

.footer .count {
  margin: 0 10px;
  font: var(--C1);
}
