.grocerySelectCategory {
  min-height: 100vh;
}

.header {
  background: var(--primary-header-color);
  width: 100%;
  top: 0;
  padding: 16px;
}

.header.scrolled {
  background: #fff;
  position: fixed;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
}

.upper {
  margin-bottom: 16px;
  border: 0px 0px 1px 0px;
  transition: all 0.3s ease;
}

.upper a {
  padding: 8px 10px;
  gap: 2px;
  border-radius: 16px;
  background: #ffffff;
  gap: 8px;
  background: #ffffff;
  color: var(--Neutral-500, #323e48);
}

.upper span {
  cursor: pointer;
  background-color: #fff;
  border-radius: 100px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  gap: 8px;
}

.title h1 {
  font: var(--H1);
  text-align: left;
  color: var(--Neutral-500, #323e48);
  transition: all 0.3s ease;
}

.header.scrolled h1 {
  font: var(--H5-M);
  text-align: center;
  margin-top: 5px;
  color: var(--Neutral-500, #323e48);
}

.title p {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-400, #626b73);
  padding: 8px 0;
  transition: all 0.3s ease;
}

.data {
  padding: 16px 8px 8px 8px ;
  position: relative;
  border-radius: 16px;
  background-color: #fff;
  top: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grocerySelectCategory .search {
  position: relative;
}
.grocerySelectCategory .search input {
  padding-left: 48px !important;
  width: 343px;
  padding: 14px 16px;
  gap: 8px;
  border-radius: 16px;
  font: var(--B1-L);
  color: var(--Neutral-300, #8b98a4);
  border: 1px solid var(--Neutral-100, #e4e8ec);
  outline: #000 !important;
}

.grocerySelectCategory .search input:focus{
  border: 1px solid #000;
  outline: #000 !important;
}

.grocerySelectCategory .search span {
  position: absolute;
  left: 16px;
  top: 25%;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0;
  justify-content: center;
  gap: 16px;
  margin-bottom: 6em;
}

.categories .category {
  flex-basis: calc(33.33% - 16px); 
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.categories .category .imageContainer{
  border-radius: 16px;
  height: 110px;
  width: 107.67px;
}

.categories .category img{
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
.categories .category p{
font: var(--B3-R);
letter-spacing: 0.01em;
color: var(--Neutral-500, #323E48);
margin-top: 12px;
}




.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e4e8ec;
  padding: 16px;
  gap: 8px;
  background: #ffff;
}

.footer .continue {
  width: 100%;
  padding: 10px 16px;
  gap: 8px;
  border-radius: 16px;
  background: var(--primary-linear-background);
  font: var(--C1);
  box-shadow: var(--CTA-Sh);

  text-align: center;
  color: #ffffff;
}


.footer p{
  font: var(--C1);
  color: #ffff

}

.footer span{
  background: #FFFFFF4D;
  padding: 4px 12px;
  border-radius: 8px;
}