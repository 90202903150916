.selectTimeC{
  min-height: 100vh;
}

.header {
    background: var(--primary-header-color);
    width: 100%;
    top: 0;
  }
  
  .upper {
    padding: 16px;
    border: 0px 0px 1px 0px;
  }
  
  .upper a {
    padding: 8px 10px;
    gap: 2px;
    border-radius: 16px;
    background: #ffffff;
    gap: 8px;
    background: #ffffff;
    color: var(--Neutral-500, #323e48);
  }
  
  .upper span {
    cursor: pointer;
    background-color: #fff;
    border-radius: 100px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .title {
    gap: 8px;
    padding: 0 16px 16px 16px;
  }
  
  .title h1 {
    font: var(--H1);
    text-align: left;
    color: var(--Neutral-500, #323e48);
  }
  
  .title p {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-400, #626b73);
    padding: 8px 0;
  }
  
  .article {
    padding: 16px;
    gap: 16px;
  }
  .article h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: var(--Neutral-500, #323e48);
    margin-bottom: 8px;
  }
  
  .article p {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-400, #626b73);
  }
  

  .laundrySections {
    display: flex;
    background-color: #fff;
    width: 100%;
    border-radius: 16px;
    flex-direction: column;
    position: relative;
    top: -8px;
    padding: 16px;
    gap: 10px;
  }
  
  .section {
    padding: 16px;
    gap: 16px;
    border-radius: 16px;
    border: 1px solid var(--Neutral-100, #e4e8ec);
    cursor: pointer;
  }

  .timeData p:nth-of-type(1){
    font: var(--B2-R);
    text-align: left;
    background: var(--primary-linear-background);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 8px;
  }

  .timeData p:nth-of-type(2){
    font: var(--B1-R);
    text-align: left;
    color: var(--Neutral-500, #323E48);
    margin-bottom: 4px;

  }

  .timeData p:nth-of-type(3){
    font: var(--B2-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-300, #8B98A4);
  }


.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #E4E8EC;
    padding: 16px;
    gap: 8px;
    background: #ffff;
}

.footer button{
    width: 100%;
    padding: 14px 16px 14px 16px;
    gap: 8px;
    border-radius: 16px;
    background: var(--primary-linear-background);
    font: var(--C1);
    box-shadow: var(--CTA-Sh);
    text-align: center;
    color: #ffffff;
}