.container {
  background: #f2f5f8;

}


.header {
  background: var(--primary-header-color);
  width: 100%;
  height: 128px;
  top: 0;
}


.upper {
  width: 100%;
  padding: 16px;
}

.upper button {
  padding: 8px 10px 8px 10px;
  gap: 4px;
  border-radius: 16px;
  background: #ffffff;
  font: var(--B2-M);
  text-align: center;
  color: var(--Neutral-500, #323e48);
}

.backArrow {
  cursor: pointer;
  background-color: #fff;
  border-radius: 100px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.serviceHeader {
  padding: 4px 16px;
}
.serviceHeader h1 {
  font: var(--H1);
  text-align: left;
  color: var(--Neutral-500, #323e48);
}

.serviceHeader span {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: right;
  color: var(--Neutral-300, #8b98a4);
}

.subHeader span {
  padding: 6px 8px 6px 8px;
  gap: 4px;
  border-radius: 8px;
  color: #3bab78;
  background: #ebf7f1;
  font: var(--B3-R);
  letter-spacing: 0.01em;
  text-align: center;
}

.subHeader .completed {
  color: #323e48;
  background: var(--Neutral-50, #f2f5f8);
}

.subHeader .canceled {
  color: #d83432;
  background: #fbebea;
}

.foodBanner{
  background: #EBF7F1;
  padding: 16px;
  border-radius: 16px;
  
}

.foodBanner h4{
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #3BAB78 !important;
  margin-bottom: 4px;
}
.foodBanner p{
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-500, #323E48);

}

.details{
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.details,
.additionalNotes,
.cancellationPolicy,
.paymentSummary{
  padding: 16px;
  background-color: #fff;
  margin-bottom: 4px;
  position: relative;
  top: -8px
}

.service{
  padding: 16px;
  background-color: #fff;
  margin-bottom: 4px;
}

.service h4,
.details h4,
.additionalNotes h4,
.cancellationPolicy h4,
.paymentSummary h4 {
  font: var(--H5-M);
  text-align: left;
  color: var(--Neutral-500, #323e48);
}


.section {
  padding: 16px 0;
  gap: 16px;
  border-radius: 12px;
 border-bottom: 0.5px solid var(--Neutral-50-op);
}

.section:last-child{
    border-bottom: none;
}

.section h2 {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-500, #323e48);
}
.section p {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-300, #8b98a4);
  margin-top: 2px;
}

.sectionData p {
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-300, #8b98a4);
}

.section img {
  width: 48px;
  height: 48px;
  border-radius: 8px;
}

.section span {
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-100, #E4E8EC);
  font: var(--B1-M);
  text-align: center;
  background: var(--primary-linear-background-180);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.serviceDetail {
  padding: 16px 0 0 0;
  gap: 10px;
  border: 0px 0px 0.5px 0px;
}

.service .serviceDetail p:nth-of-type(1) {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-500, #323e48);
  margin-bottom: 2px;
}

.service .serviceDetail p:nth-of-type(2) {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-300, #8b98a4);
}

.detailsItem {
  align-self: center;
  padding: 16px 0;
  gap: 10px;
 border-bottom: 0.5px solid var(--Neutral-50-op);;
}

.detailsItem:first-child {
  padding-top: 0;
}

.detailsItem:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.detailsItem p:first-of-type {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-500, #323e48);
}


.detailsItem .times p:last-of-type{
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-300, #8B98A4);

    
  }

  .section .beforeDiscount{
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-200, #BCC4CC);
    text-decoration: line-through;
  
  }
  
  .serviceDetail .discountAmount p {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: #3BAB78;
  }

.additionalNotes .serviceDetail input {
  width: 100%;
  gap: 8px;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid var(--Neutral-100, #e4e8ec);
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
}

.contentTable {
  border-radius: 16px;
  border: 1px solid var(--Neutral-100, #e4e8ec);
  width: 100%;
}

.contentTable .dataContent {
  padding: 16px;
 border-bottom: 0.5px solid var(--Neutral-50-op);
}

.contentTable .dataContent .tablefeePolicy {
  color: var(--Neutral-300, var(--Neutral-300, #8b98a4));
  text-align: left;
  font: var(--B1-L);
  letter-spacing: 0.14px;
}

.contentTable .dataContent .tableTimePolicy,
.contentTable .dataContent .header {
  color: var(--Neutral-500, var(--Neutral-500, #323e48));
  font: var(--B1-L);
  letter-spacing: 0.14px;
  text-align: left;
}

.dataContent .header {
  font-weight: 500 !important;
}

.dataContent:last-child {
  border-bottom: none;
}

.amounts {
  width: 100%;
}

.amounts p {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  color: var(--Neutral-500, #323e48);
}

.amounts .deliveryFee,
.amounts .paymentMethod,
.amounts .subTotal {
  margin-top: 16px;
  margin-bottom: 16px;
}

.amounts .total {
  margin-top: 16px;
}
.amounts .paymentMethod {
  margin: 0;
  margin-bottom: 16px;
}

.amounts .total p {
  font: var(--B1-M);
}

.footer {
  width: 100%;
  height: 100%;
  padding: 16px;
  gap: 8px;
  background: #ffff;
  position: relative;
  top: -8px;
}

.footer button {
  padding: 14px 16px 14px 16px;
  gap: 8px;
  width: 100%;
  border-radius: 16px;
  border: 1.5px solid #d83432;
  color: #d83432;
  font: var(--C1);
  text-align: center;
}

.cancelDim {
  background: var(--Neutral-100, #E4E8EC);
  color: var(--Neutral-300, #8B98A4) !important;
  border: none !important;

}