.towelSelectItems{
  min-height: 100vh;
}

.header {
  width: 100%;
  position: relative;
}

.header .image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 280px;
  background:
    url("/public/services/towels/towel.png") lightgray 50% / cover no-repeat;
}

.header .image p {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
}

.backArrow {
  position:absolute;
  top: 16px;
  left: 16px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.article{
  padding: 16px;
  gap: 16px;

}
.article h2{
  font: var(--H2);
  text-align: left;
  color: var(--Neutral-500, #323E48);
  margin-bottom: 8px;

}

.article p{
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-400, #626B73);

}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #E4E8EC;
  padding: 16px;
  gap: 8px;
  background: #ffff;
}

.footer .continue{
  width: 60%;
  padding: 14px 16px 14px 16px;
  gap: 8px;
  border-radius: 16px;
  background: var(--primary-linear-background);
  font: var(--C1);
  box-shadow: var(--CTA-Sh);
  text-align: center;
  color: #ffffff;
}
.footer .continue p{
  font: var(--C1);
  text-align: right;

}

.counter {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid var(--Neutral-100, #E4E8EC)
  
}

.button{
  padding: 18px 14px 18px 14px;
  gap: 2px;

}

.count {
  margin: 0 10px;
  font: var(--C1);
}

