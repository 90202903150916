.menuSections {
    padding: 16px 8px 8px 8px ;
    gap: 8px;
    position: relative;
    z-index: 10;
    border-radius: 16px;
    background-color: #fff;
  }
  
  .menuSections.scrolledMenu {
    position: fixed;
    top: 45px; 
    background: #fff;
    width: 100%;
  }
  
  .menuSections::-webkit-scrollbar {
    display: none;
  }
  
  .menuSections .sectionsHeader {
    gap: 8px;
    overflow-x: auto;
    scrollbar-width: none;
  }
  
  .menuSections .sectionsHeader li {
    padding: 8px 12px 8px 12px;
    gap: 4px;
    border: 1px solid var(--Neutral-100, #e4e8ec);
    border-radius: 20px;
    font: var(--B2-R);
    text-align: center;
    color: var(--Neutral-500, #323e48);
    text-wrap: nowrap;
  }
  
  .menuSections .sectionsHeader li:first-of-type{
    margin-left: 56px;
  
  }
  
  .menuSections .sectionsHeader .active {
    border: 1px solid #D8DCFE !important;
    background: var(--selected-linear-background);
  
  }


  .sectionDrop{
    left: 8px;
    padding: 8px 14px 8px 8px;
    background: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 70.8%, rgba(255, 255, 255, 0) 100%);
    position: absolute;  
  }
  
  .sectionDrop svg{
    cursor: pointer;
    z-index: 999;
    position: relative;
  }
  
  .sectionDropDown {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 16px !important;
    top: 80px !important;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 999;
    /* inset: 0px; */
    width: 50%;
    border-radius: 16px;
    max-height: 60vh;
    overflow-y: scroll;
  }
  
  .dropdownItem {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-400, #626B73);
    padding: 12px 16px;
    gap: 8px;
  }
  
  .sectionDropDown .active {
    background: var(--primary-linear-background);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font: var(--B1-M);
    text-align: left;
    border-left: 3px solid var(--primary-color2);
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #323E481A;
    z-index: 50;
  }
  