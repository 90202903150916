.customToast {
    padding: 16px;
    border-radius: 40px;
    font: var(--B2-M);
    text-align: center;
  }
  
  .toastError {
    background: #FBEBEA;
    border: 1px solid #f5c6cb;
  }
  
  .toastWarning {
    background: #FFF5E5;
    border: 1px solid #FFC666;

  }
  
  .toastInfo {
    background: #E5F3F7;
    border: 1px solid #66B5D1
  }
  
  .toastSuccess {
    background: #EBF7F1;
    border: 1px solid #89CDAE
  }
  
  .noContainer {
    background: transparent;
    box-shadow: none;
    padding: 0;
  }