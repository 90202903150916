.recentOrders {
    margin-top: 16px;
}

.orderLoading,
.orderItem{
    padding: 16px;
    gap: 16px;
    border-radius: 16px;
    background: var(--Neutral-500, #323E48);
    margin-bottom: 8px;
}

.card {
    display: flex;
  }
  
  .card .percent {
    position: relative;
  }
  
  .card svg {
    position: relative;
    width: 52.5px; /* Further reduced size */
    height: 52.5px; 
    transform: rotate(270deg);
    animation: spin 2s linear infinite;

  }
  
  .card svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke-width: 2;
    stroke-linecap: round;
  }
  
  .card svg circle:last-of-type {
    stroke-dasharray: 157px; /* Further reduced size */
    stroke-dashoffset: calc(157px - (157px * var(--percent)) / 100);
    stroke: #3498db;
  }
  
  .card .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .card .number h3 {
    font-weight: 200;
    font-size: 1.25rem; /* Half of the original size */
  }
  
  .card .number h3 span {
    font-size: 1rem; /* Half of the original size */
  }
  
  .card .title h2 {
    margin: 25px 0 0;
  }
  
  .card:nth-child(1) svg circle:last-of-type {
    stroke: #fff;
  }
  
  .card:nth-child(2) svg circle:last-of-type {
    stroke: #2ecc71;
  }
  

.data {
    align-self: center;
}
.data p:nth-of-type(1){
    font: var(--B1-R);
    letter-spacing: 0.01em;
    text-align: left;
    color: #FFFFFF;

}
.data p:nth-of-type(2){
    font: var(--B2-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-300, #8B98A4);

}




@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}