.items{
    margin-top: 7em;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-bottom: 6em;
}


.searchItems{
  margin: 16px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 6em;
}


.item{
    border-radius: 16px;
    flex-basis: 45%;
    border: 1px solid var(--Neutral-50, #F2F5F8)
}

.imageContainer{
    height: 160px;
    position: relative;
}
.imageContainer img{
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    height: 100%;
    width: 100%;
}

.info{
    padding: 12px;
    border-top:  1px solid var(--Neutral-50, #F2F5F8);
}
.info .price {
    font: var(--B1-M);
    color: var(--Neutral-500, #323E48);
    margin-bottom: 4px;
}

.info .name {
    font: var(--B2-L);
    letter-spacing: 0.01em;
    color: var(--Neutral-500, #323E48);
}


.addGroceries{
    position: absolute;
    bottom: 8px;
    right: 7px;
}



.container {
    position: relative;
    width: 40px;
    height: 40px;
    padding: 8px 10px;
    gap: 2px;
    border-radius: 10px;
    background: #ffffff;
    cursor: pointer;
    border: 1px solid var(--Neutral-100, #E4E8EC);
    transition: width 0.3s ease;
    overflow: hidden;
  }
  
  .opened {
    width: 98px;
  }

  

.plusButton,
.counter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}


.hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.visible {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.collapsed{
    background: var(--primary-linear-background);
    border: none;
}

.collapsed .counter .count {
    background: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 17.5px;
}
  
.counter {
    display: flex;
    /* gap: 16px; */
    border-radius: 16px;
    padding: 0 14px;
  }
  
  
  /* .counter button:last-child{
    margin-left: 3px;
  } */
  
  .count {
    font: var(--B1-M) ;
    text-align: center;
    color: var(--Neutral-500, #323E48);
    margin: 0 16px;
  }


.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e4e8ec;
    padding: 16px;
    gap: 8px;
    background: #ffff;
  }
  
  .footer .continue {
    width: 100%;
    padding: 10px 16px;
    gap: 8px;
    border-radius: 16px;
    background: var(--primary-linear-background);
    font: var(--C1);
    box-shadow: var(--CTA-Sh);
  
    text-align: center;
    color: #ffffff;
  }
  
  
  .footer p{
    font: var(--C1);
    color: #ffff
  
  }
  
  .footer span{
    background: #FFFFFF4D;
    padding: 4px 12px;
    border-radius: 8px;
  }