.header{
    width: 100%;
    height: 70vh;
    background: var(--primary-app-header-color);
    overflow: hidden !important;
}

.header h2{
    position: absolute;
    top: 12%;
    margin-left: 24px;
    font: var(--D0);
    text-align: left;
    color: var(--Neutral-600);
}

.header span{
    background: var(--primary-linear-background-180);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.services {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0 16px;
    gap: 16px;
    border-radius: 16px 16px 0px 0px;
    background-color: #fff;
    overflow-y: scroll;
    scrollbar-width: none;
}

.newOrders {
    max-height: 107px;
}

.sectionItem {
   border-bottom: 0.5px solid var(--Neutral-50-op);
    padding-bottom: 8px;
}

.sectionItem:nth-last-of-type(2) {
    border-bottom: none ;
    padding-bottom: 0;
}
.imageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 76px;
    height: 76px;
}
.sectionItem img{
    border-radius: 12px;
    width: 150%;
}

.sectionItem article{
    width: 60%;
}
.sectionItem article h2{
    font: var(--H6);
    text-align: left;
    flex: 3;
    color: var(--Neutral-500, #323E48);
}
.sectionItem article p{
    font: var(--B2-L);
    text-align: left;
    letter-spacing: 0.01em;
    color: var(--Neutral-300, #8B98A4); 
}


.sectionItem .price{
    font: var(--H6);
    text-align: right;
    color: var(--Neutral-500, #323E48);
}

hr {
    border: none;
    height: 1px;
    color: var(--Neutral-50-op);
    background-color: var(--Neutral-50-op)
  }
  

  .unavailableImage {
    opacity: 0.6;
  }
  
  .disabledText {
    color: #ccc !important;
  }
  
  
  .descriptionUnavailable {
    color: #ccc !important;
  }