/* NotFound.module.css */
.section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.emptyOrders{
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

      
.page {
  margin: 34px 0 2em 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
}

.page h2{
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: var(--Neutral-500, #323E48);
}

.page p{
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: center;
  color: var(--Neutral-300, #8B98A4);
}

.backHome {
  width: 343px;
  padding: 14px 16px 14px 16px;
  gap: 8px;
  border-radius: 16px;
  background: var(--primary-linear-background);
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-home:hover {
  background: #222;
  color: #ddd;
}
    