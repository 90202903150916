.container{
    background: #F2F5F8;
    padding-bottom: 80px;
}

.upper {
    width: 100%;
    background-color: #Fff;
    padding: 16px;
  }

.backArrow {
    cursor: pointer;
    border: 1px solid var(--Neutral-100, #E4E8EC);
    border-radius: 100px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.extaData{
    padding-bottom: 4em;
}
.checkOut h1 {
    font: var(--H1);
    text-align: left;
    color: var(--Neutral-500, #323E48);
    padding: 4px 16px ;
    background-color: #fff;

}
.service,
.details,
.additionalNotes,
.cancellationPolicy,
.paymentSummary
{
    padding: 16px;
    background-color: #fff;
    margin-bottom: 4px;
}

.service h4,
.details h4,
.additionalNotes h4,
.cancellationPolicy h4,
.paymentSummary h4
{
    font: var(--H5-M);
    text-align: left;
    color: var(--Neutral-500, #323E48);

}

.serviceDetail{
    padding: 16px 0 0 0;
    gap: 10px;
    border: 0px 0px 0.5px 0px;
}


.service .serviceDetail p:nth-of-type(1){
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-500, #323E48);
    margin-bottom: 2px;
}

.service .serviceDetail p:nth-of-type(2){
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-300, #8B98A4);
}

.detailsItem {
    padding: 16px 0;
    gap: 10px;
   border-bottom: 0.5px solid var(--Neutral-50-op);
}

.detailsItem:first-child {
    padding-top: 0;
}

.detailsItem:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.detailsItem p {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-500, #323E48);

}


.additionalNotes .serviceDetail input {
    width: 100%;
    gap: 8px;
    border-radius: 16px;
    padding: 16px;
    border: 1px solid var(--Neutral-100, #E4E8EC);
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    outline: none;
}

.additionalNotes .serviceDetail input:focus {
    border: 1.5px solid var(--Neutral-500, #323E48);

}

.contentTable{
    border-radius: 16px;
    border: 1px solid var(--Neutral-100, #E4E8EC);
    width: 100%;
  }
  
  .contentTable .dataContent{
    padding: 16px;
   border-bottom: .5px solid var(--Neutral-100) !important;
  }


.contentTable .dataContent .tablefeePolicy{
  color: var(--Neutral-300, #8B98A4);
  text-align: left;
  font: var(--B1-L);
  letter-spacing: 0.14px;
}

.contentTable .dataContent .tableTimePolicy,
.contentTable .dataContent .header
{
  color: var(--Neutral-500, #323E48);
  font: var(--B1-L);
  letter-spacing: 0.14px;
  text-align: left;
}

.dataContent .header{
    font: var(--B1-M) !important;

}

.dataContent:last-child{
    border-bottom: none !important;
}

.paymentMethod {
    padding-bottom: 16px;
   border-bottom: 0.5px solid var(--Neutral-50-op);
    margin-bottom: 16px;
}

.amounts {
    width: 100%;
}

.amounts p {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    color: var(--Neutral-500, #323E48);
}
.amounts .deliveryFee{
    margin-top: 8px;
    margin-bottom: 16px;
} 

.amounts .total p{
    font: var(--B1-M);

} 
.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #E4E8EC;
    padding: 16px;
    gap: 8px;
    background: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
}

.footer .phoneButton{
    width: 100%;
    padding: 14px 16px 14px 16px;
    gap: 8px;
    border-radius: 16px;
    background: var(--primary-linear-background);
    font: var(--C1);
    box-shadow: var(--CTA-Sh);
    text-align: center;
    color: #ffffff;
}