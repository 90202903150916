.container{
    gap: 16px;
}

.container h4{
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #323E48;

}

.container p{
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #626B73;
}

.actions{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.actions button {
    padding: 14px 16px;
    gap: 8px;
    border-radius: 16px;
    border: 1.5px;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;

}

.actions button:nth-of-type(1) {
    background: #D83432;
    color: #FFFFFF;

}
.actions button:nth-of-type(2) {
    border: 1.5px solid var(--Neutral-100, #E4E8EC)
}
