.container{
    background: #F2F5F8;
    min-height: 100vh;
}

.upper {
    width: 100%;
    background-color: #Fff;
    padding: 16px;
  }

.backArrow {
    cursor: pointer;
    border: 1px solid var(--Neutral-100, #E4E8EC);
    border-radius: 100px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.extaData{
    padding-bottom: 10em;
}

.checkOut h1 {
    font: var(--H1);
    text-align: left;
    color: var(--Neutral-500, #323E48);
    padding: 4px 16px ;
    background-color: #fff;
}
.service,
.details,
.additionalNotes,
.cancellationPolicy,
.paymentSummary
{
    padding: 16px;
    background-color: #fff;
    margin-bottom: 4px;
}

.service h4,
.details h4,
.additionalNotes h4,
.cancellationPolicy h4,
.paymentSummary h4
{
    font: var(--H5-M);
    text-align: left;
    color: var(--Neutral-500, #323E48);

}

.section {
    padding: 24px 0 16px 0;
    gap: 16px;
   border-bottom: 0.5px solid var(--Neutral-50-op);
  }

.section:last-child {
border-bottom: none
}

.section h2 {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-500, #323E48);
  }
  .section p {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-300, #8B98A4);
    margin-top: 2px;

  }
  
  .sectionData p {
    font-size: 13px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-300, #8b98a4);
  }

  .section img {
    width: 64px;
    height: 64px;
    border-radius: 8px;
  }

  .section .counter {
    left: 1.5px;
    padding: 10px 16px;
    border-radius: 8px;
    border: 1px solid var(--Neutral-100, #E4E8EC);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    background: var(--primary-linear-background-180);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 40px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  .section .counter .button {
    opacity: 1;
    max-height: 0;
    position: absolute;
    left: -50px;
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
  }
  
  .section .counter .buttonShow {
    opacity: 1;
    position: relative;
    left: initial;  
    max-height: 100px; /* Adjust this value as necessary */
  }
  
  .section .counterShow {
    height: 102px;
  }

.serviceDetail{
    padding: 16px 0 0 0;
    gap: 10px;
    border: 0px 0px 0.5px 0px;
}


.service .serviceDetail p:nth-of-type(1){
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-500, #323E48);
    margin-bottom: 2px;
}

.service .serviceDetail p:nth-of-type(2){
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-300, #8B98A4);
}

.detailsItem {
    padding: 16px 0;
    gap: 10px;
    border-bottom: 1px solid var(--Neutral-50-op);

}

.detailsItem:first-child {
    padding-top: 0;
}

.detailsItem:last-child {
    border-bottom: none;
    padding-bottom: 0;
}


.detailsItem p {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-500, #323E48);

}

.detailsItem .times p:nth-of-type(1) {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-500, #323E48);

}

.detailsItem .times p:nth-of-type(2) {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-300, #8B98A4);
    margin-top: 2px;

}


.section .beforeDiscount{
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-200, #BCC4CC);
  text-decoration: line-through;

}


.serviceDetail .discountAmount p {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: #3BAB78;
}


.additionalNotes .serviceDetail input {
    width: 100%;
    gap: 8px;
    border-radius: 16px;
    padding: 16px;
    border: 1px solid var(--Neutral-100, #E4E8EC);
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    outline: none;
}

.additionalNotes .serviceDetail input:focus {
    border: 1.5px solid var(--Neutral-500, #323E48);

}

.contentTable{
    border-radius: 16px;
    border: 1px solid var(--Neutral-100, #E4E8EC);
    width: 100%;
  }
  
  .contentTable .dataContent{
    padding: 16px;
   border-bottom: 0.5px solid var(--Neutral-50-op);
  }




.dataContent:last-child{
    border-bottom: none;
}

.amounts .deliveryFee,
.paymentMethod,
.amounts .subTotal{
    margin-top: 16px;
    margin-bottom: 16px;
} 

.amounts .total{
    margin-top: 16px;
}
.paymentMethod{
    margin: 0;
    margin-bottom: 16px;
}

.amounts {
    width: 100%;
}

.amounts p {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    color: var(--Neutral-500, #323E48);
} 

.amounts .total p{
  font: var(--B1-M);
} 

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e4e8ec;
    padding: 16px;
    gap: 8px;
    background: #ffff;
  }
  
  .footer .continue {
    width: 100%;
    padding: 10px 16px;
    gap: 8px;
    border-radius: 16px;
    background: var(--primary-linear-background);
    font: var(--C1);
    box-shadow: var(--CTA-Sh);
    text-align: center;
    color: #ffffff;
  }
  
  
  .footer p{
    font: var(--C1);
    color: #ffff
  
  }
  
  .footer span{
    background: #FFFFFF4D;
    padding: 4px 12px;
    border-radius: 8px;
  }