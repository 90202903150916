.grocerySelectCategory {
    min-height: 100vh;
  }
  
  .header {
    background: var(--primary-header-color);
    width: 100%;
    top: 0;
    padding: 16px;
    display: flex;
    align-items: center;
  }
  
  .header.scrolled {
    background: #fff;
    position: fixed;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    z-index: 10;
  }
  
  .upper {
    border: 0px 0px 1px 0px;
    transition: all 0.3s ease;
  }
  
  .upper a {
    padding: 8px 10px;
    gap: 2px;
    border-radius: 16px;
    background: #ffffff;
    gap: 8px;
    background: #ffffff;
    color: var(--Neutral-500, #323e48);
  }
  
  .upper span {
    cursor: pointer;
    background-color: #fff;
    border-radius: 100px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .title {
    gap: 8px;
  }
  
  .title h1 {
    font: var(--H1);
    text-align: left;
    color: var(--Neutral-500, #323e48);
    transition: all 0.3s ease;
  }
  
  .header.scrolled h1 {
    font: var(--H5-M);
    text-align: center;
    margin-top: 5px;
    color: var(--Neutral-500, #323e48);
  }
  
  .title p {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-400, #626b73);
    padding: 8px 0;
    transition: all 0.3s ease;
  }


  .arrowBack svg {
    margin: 12px 0 12px 16px;
  }
  .searchContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .grocerySelectCategory .search {
    position: relative;
  }
  .grocerySelectCategory .search input {
    padding-left: 48px !important;
    width: 343px;
    padding: 14px 16px;
    gap: 8px;
    border-radius: 16px;
    font: var(--B1-L);
    color: var(--Neutral-300, #8b98a4);
    border: 1px solid var(--Neutral-100, #e4e8ec);
    outline: #000 !important;
  }

  .grocerySelectCategory .search input:focus{
    border: 1px solid #000 !important;
    outline: #000 !important;
  }
  
  .grocerySelectCategory .search span {
    position: absolute;
    left: 16px;
    top: 25%;
  }