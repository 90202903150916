.foodSelectItems{
  min-height: 100vh;
}

.header {
  background: var(--primary-header-color);
  width: 100%;
  top: 0;
  padding: 16px;
}

.header.scrolled {
  background: #fff;
  position: fixed;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  z-index: 10;

}

.upper {
  margin-bottom: 16px;
  border: 0px 0px 1px 0px;
  transition: all 0.3s ease;
}

.upper a {
  padding: 8px 10px;
  gap: 2px;
  border-radius: 16px;
  background: #ffffff;
  gap: 8px;
  background: #ffffff;
  color: var(--Neutral-500, #323e48);
}

.upper span {
  cursor: pointer;
  background-color: #fff;
  border-radius: 100px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  gap: 8px;
}

.title h1 {
  font: var(--H1);
  text-align: left;
  color: var(--Neutral-500, #323e48);
  transition: all 0.3s ease;

}

.header.scrolled h1 {
  font: var(--H5-M);
  text-align: center;
  margin-top: 5px;
  color: var(--Neutral-500, #323E48);

  }

.title p {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-400, #626b73);
  padding: 8px 0;
  transition: all 0.3s ease;
}


.menuSections {
  padding: 16px 8px 8px 8px ;
  gap: 8px;
  position: relative;
  z-index: 10;
  border-radius: 16px;
  background-color: #fff;
  top: -10px;
}

.menuSections.scrolledMenu {
  position: fixed;
  top: 45px; 
  background: #fff;
  width: 100%;
  border-bottom: .5px solid var(--Neutral-50-op) ;
}

.menuSections::-webkit-scrollbar {
  display: none;
}

.menuSections .sectionsHeader {
  gap: 8px;
  overflow-x: auto;
  scrollbar-width: none;
}

.menuSections .sectionsHeader li {
  padding: 8px 12px 8px 12px;
  gap: 4px;
  border: 1px solid var(--Neutral-100, #e4e8ec);
  border-radius: 20px;
  font: var(--B2-R);
  text-align: center;
  color: var(--Neutral-500, #323e48);
  text-wrap: nowrap;
}

.menuSections .sectionsHeader li:first-of-type{
  margin-left: 56px;

}

.menuSections .sectionsHeader .active {
  border: 1px solid #D8DCFE !important;
  background: var(--selected-linear-background);

}

.group {
  padding: 0px 16px;
}

.group h3{
  font: var(--H4-M);
  text-align: left;
  color: var(--Neutral-500, #323E48);
  margin-bottom: 4px;
}
/* itmes */
.items .item {
  border-bottom: .5px solid var(--Neutral-50-op);
  padding: 12px 0;
  gap: 12px;
}


.items .item:last-child {
  border: none
}

.items .item img {
  border-radius: 12px;
  width: 140px;
  height: 136px;
}

.sectionDrop{
  left: 8px;
  padding: 8px 14px 8px 8px;
  background: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 70.8%, rgba(255, 255, 255, 0) 100%);
  position: absolute;  
}

.sectionDrop svg{
  cursor: pointer;
  z-index: 999;
  position: relative;
}

.sectionDropDown {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 16px !important;
  top: 80px !important;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
  inset: 0px;
  width: 50%;
  border-radius: 16px;
  height: 60vh;
  overflow-y: scroll;
}

.dropdownItem {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-400, #626B73);
  padding: 12px 16px;
  gap: 8px;
}

.sectionDropDown .active {
  background: var(--primary-linear-background);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: var(--B1-M);
  text-align: left;
  border-left: 3px solid var(--primary-color2);


}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #323E481A;
  z-index: 50;
}

.items .item .itemData {
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.sectionItems{
  margin-bottom: 100px;
  margin-top: 8px;
}

.items .item .name {
  font: var(--B1-R);
  text-align: left;
  color: var(--Neutral-500, #323e48);
}

.items .item .description {
  font: var(--B3-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-300, #8b98a4);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; 
  overflow: hidden;
  text-overflow: ellipsis;
  height: calc(16px * 2);
}

.items .item .totalPrice,
.items .item .price {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-300, #8b98a4);
  justify-self: flex-end;
  margin-top: 16px;
}

.totalPrice{
  text-decoration: line-through;
  color: var(--Neutral-200, #BCC4CC) !important;
}


.outOfStockText {
  color: var(--Neutral-200, #BCC4CC) !important;
}

.outOfstockImage{
  opacity: .3;
}


.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e4e8ec;
  padding: 16px;
  gap: 8px;
  background: #ffff;
}

.footer .continue {
  width: 100%;
  padding: 10px 16px;
  gap: 8px;
  border-radius: 16px;
  background: var(--primary-linear-background);
  font: var(--C1);
  box-shadow: var(--CTA-Sh);

  text-align: center;
  color: #ffffff;
}


.footer p{
  font: var(--C1);
  color: #ffff

}

.footer span{
  background: #FFFFFF4D;
  padding: 4px 12px;
  border-radius: 8px;
}