.container{
    min-height: 100vh;
}


header {
    background: var(--primary-header-color);
    width: 100%;
    top: 0;
}

.upper {
    padding: 16px;
    border: 0px 0px 1px 0px;
}

.upper a {
    padding: 9px 12px;
    border-radius: 16px;
    background: #FFFFFF;
    gap: 16px;
    color: var(--Neutral-500, #323E48);
    font: var(--B2-M);
    text-align: center;

}

.upper span {
    cursor: pointer;
    background-color: #fff;
    border-radius: 100px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    gap: 8px;
    padding: 0 16px 16px 16px;
}

.title h1 {
    font: var(--H1);
    text-align: left;
    color: var(--Neutral-500, #323E48);

}

.title p {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-400, #626B73);
    padding: 8px 0;

}

.cleaningTime {
    background-color: #fff;
    position: relative;
    top: -8px;
    width: 100%;
    border-radius: 16px;
}

.select,
.modalselect{
    padding: 0px 16px;
    gap: 10px;
    height: calc(100vh - 225px);
}

.container h4{
    margin: 16px;
    font: var(--H4-M);
    text-align: left;
    color: var(--Neutral-500, #323E48);

}

.modalselect{
    padding: 0 !important;
    height: calc(70vh - 150px); 
    margin: 0 16px;

}

.select .selectBox {
    padding: 12px 16px 12px 16px;
    gap: 4px;
    border: 1px solid var(--Neutral-100, #E4E8EC);
    border-radius: 16px;
    width: 150px;
    margin: 10px 0;
    cursor: pointer;
    min-height: 64px;
}


.select .selectBox:last-child{
    margin-bottom: 2em;
}

.select .selectBox p:nth-of-type(1){
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-500, #323E48);
    width: 118px;
    height: 20px;
    margin-bottom: 4px;
    width: 100%;
}

.select .selectBox p:nth-of-type(2){
    font: var(--B3-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-300, #8B98A4);
}

.date, .time{
    overflow-y: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}

.date::-webkit-scrollbar,
.time::-webkit-scrollbar
{
    width: 0;
    height: 0;
}
.time {
    flex-basis: 60%;
}

.date {
    flex-basis: 40%;
}
.time .selectBox {
    width: 100%;
    display: flex;
    align-items: center;
}

.time .feesSelectBox {
    align-items:start;
    justify-content: center;
}
.date .selectBox {
    width: 100%;
}

.selected {
    border: 1px solid #D8DCFE !important;
    background: var(--selected-linear-background);
}

.extraFee {
    padding: 4px 8px;
    gap: 8px;
    border-radius: 6px;
    background: #529C86;
    box-shadow: 0px 20px 40px 0px #0000000D;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #fff;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #E4E8EC;
    padding: 16px;
    gap: 8px;
    background: #ffff;
}

.footer button{
    width: 100%;
    padding: 14px 16px 14px 16px;
    gap: 8px;
    border-radius: 16px;
    background: var(--primary-linear-background);
    font: var(--C1);
    box-shadow: var(--CTA-Sh);
    text-align: center;
    color: #ffffff;
}


.skeletonLoading {
    padding: 12px 16px 12px 16px;
    gap: 4px;
    border: 1px solid var(--Neutral-100, #E4E8EC);
    border-radius: 16px;
    margin: 10px 0;
    cursor: pointer;
    min-height: 64px;
}


.modalOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transition: opacity 0.4s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }
  
  .modalContent {
    height: 70vh;
    width: 100%;
    max-width: 100%;
    border-radius: 8px 8px 0 0;
    padding: 0;
    margin: 0;
    background-color: white;
    position: absolute;
    bottom: -70vh;
    animation: slide-in 0.4s forwards;
  }
  .exit {
    animation: slide-out 0.4s forwards;
  }

  .exitOverlay{
    animation: slide-out 0.4s forwards;
  }
  
  @keyframes slide-in {
    from {
      bottom: -70vh;
    }
    to {
      bottom: 0;
    }
  }
  
  @keyframes slide-out {
    from {
      bottom: 0;
      opacity: 1;
    }
    to {
      bottom: -70vh;
      opacity: 0;
    }
  }
  