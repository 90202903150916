@import './assets/fonts/fonts.css';

body {
  font-family: Sora;
} 

:root {
  /* Colors */
  --primary-color: #5EBFFB;
  --primary-color2: #3B51F9;

  --primary-header-color: linear-gradient(180.18deg, #FFFFFF 0.69%, #E7F0FE 100.03%);  ;
  --primary-app-header-color: linear-gradient(180deg, #FFFFFF 0%, #E7F0FF 63.7%);  ;
  --primary-linear-background-180: linear-gradient(180deg, #5EBFFB 7.14%, #3B51F9 100%);

 /*CTA*/
  --primary-linear-background: linear-gradient(85.04deg, #3B51F9 2.33%, #5EBFFB 96.51%);
  --selected-linear-background: linear-gradient(180.18deg, #FFFFFF 0.69%, #E7F0FE 100.03%);
  
  
  --Neutral-600: #161D2B;
  --Neutral-500: #323E48;
  --Neutral-400: #626B73;
  --Neutral-300: #8B98A4;
  --Neutral-100: #E4E8EC;
  --Neutral-50-op: rgba(228, 232, 236, .5);
  --Neutral-50: #F2F5F8;

  /* Fonts */
    --D0: 800 42px/42px Sora; 
    --D1: 600 38px/42px Sora;
    --D2: 600 33px/40px Sora;

    --H1: 600 25px/32px Sora;
    --H2: 600 23px/32px Sora;
    --H3: 600 19px/32px Sora;
    --H4-M: 600 17px/24px Sora;
    --H4-R: 400 17px/24px Sora;
    --H5-M: 600 15px/24px Sora;
    --H5-R: 400 15px/24px Sora;
    --H6: 600 14px/20px Sora;

    --B1-M: 600 13px/20px Sora;
    --B1-R: 400 13px/20px Sora;
    --B1-L: 300 13px/20px Sora;
    --B2-M: 600 12px/18px Sora;
    --B2-R: 400 12px/18px Sora;
    --B2-L: 300 12px/18px Sora;
    --B3-M: 600 11px/16px Sora;
    --B3-R: 400 11px/16px Sora;
    --B3-L: 300 11px/16px Sora;

    --C1: 600 14px/24px Sora;
    --C2: 600 12px/16px Sora;
    --Link: 600 14px/20px Sora;
  
    /* Shadow */
    --CTA-Sh: 0px 6px 20px 0px #436BFA4D;
}   


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}