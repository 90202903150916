.header {
  width: 100%;
  position: relative;
}

.header .image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 280px;
  background: linear-gradient(
      0deg,
      rgba(50, 62, 72, 0.6) 0%,
      rgba(50, 62, 72, 0.6) 100%
    ),
    url("/public/services/cleaning/info.png") lightgray 50% / cover no-repeat;
}

.header .image p {
  color: #fff;
  text-align: center;
  font: var(--H4-M)
}

.backArrow {
  position:absolute;
  top: 16px;
  left: 16px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.article {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.article p {
  color: var(--Neutral-500, var(--Neutral-500, #323e48));
  font: var(--B1-L);
  letter-spacing: 0.14px;
}


.contentHeader p{
  color:  var(--Neutral-300, #8B98A4);
  font: var(--B1-L);
  letter-spacing: 0.14px;
}

.onlyText p{
  color: var(--Neutral-500, var(--Neutral-500, #323E48));
  font: var(--B1-L);
  letter-spacing: 0.14px;
}


.dataContent{
    padding: 16px 0;
    align-items: flex-start;
    gap: 2px;
   border-bottom: 0.5px solid var(--Neutral-50-op);;
}

.dataContent:last-child{
  border: none;
}

.dataContentItem{
    color: var(--Neutral-500, #323E48) !important;
    font: var(--B1-L);
    letter-spacing: 0.14px;
    margin-left: 8px;
}


.contentTable{
  border-radius: 16px;
  border: 1px solid var(--Neutral-100, #E4E8EC);
  margin: 16px 0;
}

.contentTable .dataContent{
  padding: 16px;
 border-bottom: 0.5px solid var(--Neutral-50-op);
}

.contentTable .dataContent .tablecontent,
.contentTable .dataContent .tableTimePolicy,
.contentTable .dataContent .header
{
  color: var(--Neutral-500, #323E48);
  font: var(--B1-L);
  letter-spacing: 0.14px;
  text-align: left;
}

.dataContent .header{
  font-weight: 500 !important;
}

.contentTable .dataContent .tablefeePolicy,
.contentTable .dataContent .tableEstimate{
  color: var(--Neutral-300, #8B98A4);
  font: var(--B1-L);
  letter-spacing: 0.14px;
}
.contentTable .dataContent .tablefeePolicy {
  text-align: left;

}

.dataContent:last-child{
  border: none;
}
