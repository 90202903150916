.container{
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container h3 {
    font: var(--H3);
    color: var(--Neutral-500, #323E48);
    margin-bottom: 8px;
}

.container p {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    color: var(--Neutral-500, #323E48);
}