.selectItemC{
  min-height: 100vh;
}

.header {
  background: var(--primary-header-color);
  width: 100%;
  top: 0;
}

.upper {
  padding: 16px;
  border: 0px 0px 1px 0px;
}

.upper a {
  padding: 8px 10px;
  gap: 2px;
  border-radius: 16px;
  background: #ffffff;
  gap: 8px;
  background: #ffffff;
  color: var(--Neutral-500, #323e48);
}

.upper span {
  cursor: pointer;
  background-color: #fff;
  border-radius: 100px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  gap: 8px;
  padding: 0 16px 16px 16px;
}

.title h1 {
  font: var(--H1);
  text-align: left;
  color: var(--Neutral-500, #323e48);
}

.title p {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-400, #626b73);
  padding: 8px 0;
}

.article {
  padding: 16px;
  gap: 16px;
}
.article h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: var(--Neutral-500, #323e48);
  margin-bottom: 8px;
}

.article p {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-400, #626b73);
}



.laundrySections {
  display: flex;
  background-color: #fff;
  width: 100%;
  border-radius: 16px;
  flex-direction: column;
  position: relative;
  top: -8px;
  padding: 16px;
  gap: 10px;
}

.section {
  padding: 8px;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid var(--Neutral-100, #e4e8ec);
}

.section:last-child{
  margin-bottom: 85px;
}

.active {
  border: 1px solid var(--primary-color2);
  background: #EFF9FF;
  animation: scale-down .4s ease-in;
  overflow: hidden;
}

.inActive {
  border: 1px solid var(--Neutral-100, #e4e8ec);
  overflow: hidden;
  animation: scale-up .4s ease-out;

}
.footer{
  animation: slide-top .4s

}
.inf{
  animation:slide-down .4s 

}
  

.sectionData h2 {
  font: var(--B1-M);
  text-align: left;
  color: var(--Neutral-500, #323e48);
}

.sectionData p {
  font: var(--B2-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-300, #8b98a4);
}

.listAddedItems{
  font: var(--B2-L);
  letter-spacing: 0.01em;
  text-align: left;
  background: var(--primary-linear-background);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.sectionData img {
  width: 56px;
  height: 56px;
  border-radius: 8px;
  margin-right: 16px;
}
.item {
  margin-top: 16px;
 border-bottom: 0.5px solid var(--Neutral-50-op);;
  padding-bottom: 16px;
}

.item:last-child {
  border: none;
}


.item h3 {
  font: var(--B2-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-500, #323e48);
}

.item .itemData{
  flex: 2;
  padding-left: 8px;
}
.item .itemData p:nth-of-type(1) {
  font: var(--B2-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-300, #8b98a4);
  margin-top: 2px;
  width: 90%;
}

.item .itemData p:nth-of-type(2) {
  font: var(--B2-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-500, #323e48);
  margin-top: 8px;
}

.item .container {
  padding: 8px 10px;
  gap: 2px;
  border-radius: 16px;
  background: #ffffff;
  cursor: pointer;
}

.item .container span {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  background: var(--primary-linear-background);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 5px;
}

.counter {
  display: flex;
  gap: 5px;
  border-radius: 16px;
}

.counter button:last-child{
  margin-left: 3px;
}

.count {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  background: var(--primary-linear-background);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e4e8ec;
  padding: 16px;
  gap: 8px;
  background: #ffff;
}

.footer .continue {
  width: 50%;
  padding: 14px 16px 14px 16px;
  gap: 8px;
  border-radius: 16px;
  background: var(--primary-linear-background);
  font: var(--C1);
  box-shadow: var(--CTA-Sh);
  text-align: center;
  color: #ffffff;
}


.footer p:nth-of-type(1){
  font: var(--B1-R);
  text-align: left;
  color: var(--Neutral-300, #8B98A4);

}

.footer p:nth-of-type(2){
  font: var(--C1);
  text-align: left;
  color: #323E48;

}

@keyframes slide-top{
  0%{transform:translateY(100px)}
  100%{transform:translateY(0)}
}

@keyframes slide-down{
  0%{transform:translateY(0)}
  100%{transform:translateY(100px)}
}

@keyframes scale-down{
  0%{height: var(--laundry-section-height);}
  100%{height: 384px}
}

@keyframes scale-up{
  0%{height: 384px;
     background: rgba(236, 245, 254, 1);
     border: 1px solid rgba(104, 176, 207, 1);
    }
  100%{height: var(--laundry-section-height);
     background: rgba(236, 245, 254, 0);
     border: 1px solid var(--Neutral-100, #e4e8ec);
    }
}