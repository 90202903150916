.successData {
  gap: 16px;
  width: 100%;
}
.successData h4 {
  font: var(--H4-M);
  text-align: center;
  color: var(--Neutral-500, #323e48);
}

.details {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid var(--Neutral-100, #e4e8ec);
}

.info {
  gap: 8px;
  padding: 0px 0px 16px 0px;
 border-bottom: 0.5px solid var(--Neutral-50-op);;
  margin-bottom: 16px;
}

.info p:nth-of-type(1) {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-500, #323e48);
}

.info p:nth-of-type(2) {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-300, #8b98a4);
}

.detailsItem p:nth-of-type(1) {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-500, #323e48);
}
.detailsItem p:nth-of-type(2) {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-300, #8b98a4);
}

.detailsItem {
  margin-bottom: 14px;
  gap: 10px;
}

.detailsItem p {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-500, #323e48);
}

.section {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: .5px solid var(--Neutral-50-op);
}
.section img {
  width: 64px;
  height: 64px;
  border-radius: 8px;

}

.section h2 {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-500, #323E48);

}

.section span {
  left: 1.5px;
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-100, #E4E8EC);
  font: var(--H6);
  text-align: center;
  background: var(--primary-linear-background-180);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.section p {
 font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-300, #8B98A4);

}

.done {
  width: 100%;
  padding: 14px 16px 14px 16px;
  gap: 8px;
  border-radius: 16px;
  background: var(--primary-linear-background);
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}


.info .section h2 {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-500, #323e48);
}

.info .section p {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-300, #8b98a4);
}



.successModal {
  height: auto;
  gap: 24px;
  border-radius: 8px 8px 0 0;
  padding: 0;
  margin: 0;
  background-color: white;
  position: absolute;
  bottom: -100%; 
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: slide-in 0.4s forwards;
  padding: 24px 16px;

}

@keyframes slide-in {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
}
