.container {
  padding: 24px 16px 0px 16px;
  display: table;
  margin: 0 auto;
}

.orders {
  padding: 0px 0px 16px 0px;
  gap: 2px;
  border-bottom: 0.5px solid var(--Neutral-100, #e4e8ec);
  cursor: pointer;
}

.orders p {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-500, #323e48);
}

.feedback {
  margin: 16px 0 0 0;
}
.feedback .card {
  width: 343px;
  height: 344.39px;
  padding: 16px;
  gap: 24px;
  border-radius: 16px;
  background: linear-gradient(
    91.62deg,
    #f76d9c -0.39%,
    #803e92 65.99%,
    #586ebb 106.34%
  );
}

.feedback .card h5 {
  font: var(--H5-R);
  text-align: center;
  color: #ffffff;
}

.feedback .card p {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: center;
  color: #ffffff;
  margin-top: 8px;
}

.feedback .card a {
  padding: 12px;
  border-radius: 16px;
  background: #ffffff;
  font: var(--B1-M);
  text-align: center;
  width: 100%;
}

.property {
  background: var(--Neutral-50, #f2f5f8);
  padding: 12px 16px;
  gap: 8px;
}

.property p {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-500, #323e48);
}

.noScroll {
  overflow: hidden;
}

.navbarModal {
  height: auto;
  gap: 16px;
  border-radius: 8px 8px 0 0;
  padding: 0;
  margin: 0;
  background-color: white;
  position: absolute;
  bottom: -100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  animation: slide-in 0.4s forwards;
}

.exit {
  animation: slide-out 0.4s forwards;
}

.exitOverlay {
  animation: slide-out 0.4s forwards;
}

@keyframes slide-in {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
}

@keyframes slide-out {
  from {
    bottom: 0;
    opacity: 1;
  }
  to {
    bottom: -100%;
    opacity: 0;
  }
}
