.itemModal {
    height: auto;
    gap: 24px;
    border-radius: 8px 8px 0 0;
    padding: 0;
    margin: 0;
    background-color: white;
    position: absolute;
    bottom: -100%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: slide-in 0.4s forwards;
  }
  
  .exit {
    animation: slide-out 0.4s forwards;
  }
  
  .exitOverlay{
    animation: slide-out 0.4s forwards;
  
  }

.header{
  padding: 16px 16px;

}
.header button {
  float: right;
  margin-bottom: 10px;
}

.imageContainer img {
  height: 375px;
  width: 100%;
}
.header h4 {
  margin-top: 16px;
  font: var(--H4-M);
  color: var(--Neutral-500, #323E48);
}

.footer {
  bottom: 0;
  width: 100%;
  border-top: 1px solid #E4E8EC;
  padding: 16px;
  gap: 8px;
  background: #ffff;
}

.footer .continue{
  width: 60%;
  padding: 14px 16px 14px 16px;
  gap: 8px;
  border-radius: 16px;
  background: var(--primary-linear-background);
  font: var(--C1);
  box-shadow: var(--CTA-Sh);
  text-align: center;
  color: #ffffff;
}
.footer .continue p{
  font: var(--C1);
  text-align: right;

}

.counter {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid var(--Neutral-100, #E4E8EC)
  
}

.button{
  padding: 18px 14px 18px 14px;
  gap: 2px;

}

.count {
  margin: 0 10px;
  font: var(--C1);
}

  @keyframes slide-in {
    from {
      bottom: -100%;
    }
    to {
      bottom: 0;
    }
  }
  
  @keyframes slide-out {
    from {
      bottom: 0;
      opacity: 1;
    }
    to {
      bottom: -100%;
      opacity: 0;
    }
  }