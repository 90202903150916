.container{
    background: #F2F5F8;
    min-height: 100vh;
}

.upper {
    width: 100%;
    background-color: #Fff;
    padding: 16px;
  }

.backArrow {
    cursor: pointer;
    border: 1px solid var(--Neutral-100, #E4E8EC);
    border-radius: 100px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.extaData{
    padding-bottom: 13em;
}

.checkOut h1 {
    font: var(--H1);
    text-align: left;
    color: var(--Neutral-500, #323E48);
    padding: 4px 16px ;
    background-color: #fff;
}
.details,
.additionalNotes,
.cancellationPolicy,
.paymentSummary
{
    padding: 16px;
    background-color: #fff;
    margin-bottom: 4px;
}

.service {
  padding: 0 16px;
  background-color: #fff;
  margin-bottom: 4px;
}

.service h4,
.details h4,
.additionalNotes h4,
.cancellationPolicy h4,
.paymentSummary h4
{
    font: var(--H5-M);
    text-align: left;
    color: var(--Neutral-500, #323E48);

}

.section {
    padding: 16px 0;
    gap: 16px;
    border-bottom: 0.5px solid var(--Neutral-50-op);
  }

.section:last-child {
border-bottom: none
}

.section h2 {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-500, #323E48);
  }
  .section p {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-300, #8B98A4);
    margin-top: 2px;

  }
  
  .sectionData p {
    font-size: 13px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-300, #8b98a4);
  }

  .section img {
    width: 64px;
    height: 64px;
    border-radius: 8px;
    border: 1px solid #E4E8EC
  }

  .section .counter {
    left: 1.5px;
    padding: 10px 16px;
    border-radius: 8px;
    border: 1px solid var(--Neutral-100, #E4E8EC);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    background: var(--primary-linear-background-180);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 40px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  .section .counter .button {
    opacity: 1;
    max-height: 0;
    position: absolute;
    left: -50px;
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
  }
  
  .section .counter .buttonShow {
    opacity: 1;
    position: relative;
    left: initial;  
    max-height: 100px; /* Adjust this value as necessary */
  }
  
  .section .counterShow {
    height: 102px;
  }


  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 23px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  
  input:checked + .slider {
    background: linear-gradient(85.04deg, #3B51F9 2.33%, #5EBFFB 96.51%);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .substitution {
    padding-top: 16px;
  }
  .substitution p {
    font: var(--B1-L) !important;
    color: var(--Neutral-500, #323E48);
  }

.substitution .hint {
    position: relative;
    display: inline-block;
  }
  
  .substitution .hint .tooltiptext {
    visibility: hidden;
    width: 343px;
    max-width: 343px;
    background: #323E48;
    border-radius: 10px;
    padding: 16px;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: -150%;
    margin-left: -130px;
    display: flex;
    align-items: center;
  }

  .substitution .hint .tooltiptext p {
    color: #fff;
    text-align: left;
    font: var(--B2-L);
    width: 98%;
  }
  
  .substitution .hint .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 52%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
  
  .substitution .hint:hover .tooltiptext {
    visibility: visible;
  }
  
.serviceDetail{
    padding: 0px 0 0 0;
    gap: 10px;
    border: 0px 0px 0.5px 0px;
}


.service .serviceDetail p:nth-of-type(1){
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-500, #323E48);
    margin-bottom: 2px;
}

.service .serviceDetail p:nth-of-type(2){
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-300, #8B98A4);
}

.detailsItem {
    padding: 16px 0;
    gap: 10px;
    border-bottom: 1px solid var(--Neutral-50-op);

}

.detailsItem:first-child {
    padding-top: 0;
}

.detailsItem:last-child {
    border-bottom: none;
    padding-bottom: 0;
}


.detailsItem p {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-500, #323E48);

}

.detailsItem .times p:nth-of-type(1) {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-500, #323E48);

}

.detailsItem .times p:nth-of-type(2) {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--Neutral-300, #8B98A4);
    margin-top: 2px;

}


.section .beforeDiscount{
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--Neutral-200, #BCC4CC);
  text-decoration: line-through;

}


.serviceDetail .discountAmount p {
  font: var(--B1-L);
  letter-spacing: 0.01em;
  text-align: left;
  color: #3BAB78;
}


.additionalNotes .serviceDetail input {
    width: 100%;
    gap: 8px;
    border-radius: 16px;
    padding: 16px;
    border: 1px solid var(--Neutral-100, #E4E8EC);
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    outline: none;
}

.additionalNotes .serviceDetail input:focus {
    border: 1.5px solid var(--Neutral-500, #323E48);

}

.contentTable{
    border-radius: 16px;
    border: 1px solid var(--Neutral-100, #E4E8EC);
    width: 100%;
  }
  
  .contentTable .dataContent{
    padding: 16px;
   border-bottom: 0.5px solid var(--Neutral-50-op);
  }




.dataContent:last-child{
    border-bottom: none;
}

.amounts .deliveryFee,
.paymentMethod,
.amounts .subTotal{
    margin-top: 16px;
    margin-bottom: 16px;
} 

.amounts .total{
    margin-top: 16px;
}
.paymentMethod{
    margin: 0;
    margin-bottom: 16px;
}

.amounts {
    width: 100%;
}

.amounts p {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    color: var(--Neutral-500, #323E48);
} 

.amounts .total p{
  font: var(--B1-M);
} 

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e4e8ec;
    padding: 16px;
    gap: 8px;
    background: #ffff;
  }
  
  .footer .continue,
  .footer .addMoreItems
  {
    width: 100%;
    padding: 10px 16px;
    gap: 8px;
    border-radius: 16px;
    background: var(--primary-linear-background);
    font: var(--C1);
    box-shadow: var(--CTA-Sh);
    text-align: center;
    color: #ffffff;
  }

  .addMoreItems {
    padding: 16px 0 !important;
  }
  
  .footer p{
    font: var(--C1);
    color: #ffff

  }
  
  .footer span{
    background: #FFFFFF4D;
    padding: 4px 12px;
    border-radius: 8px;
  }

  .footer .minimumOrder{
    font: var(--B2-R);
    text-align: center;
    color: #D83432;
    margin-top: 8px;
  }