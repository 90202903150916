
.phoneData{

    gap: 16px;
}
.phoneData h4 {
    font: var(--H4-M);
    text-align: center;
    color: var(--Neutral-500, #323E48);

}

.phoneData p {
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: center;
    color: var(--Neutral-400, #626B73);

}


.phoneInputContainer {
    display: flex;
  }
  

  .selectBtn [class$="-control"]{
    padding: 16px 0;
    border-radius: 16px;
    border: 1px solid var(--Neutral-100, #e4e8ec);
    margin-bottom: 10px;
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
  }

  .selectBtn [class$="-control"] div *{
    color: var(--Neutral-300, #8B98A4);

  }
  
  .selectBtn [class$="-menu"] {
    width: 300px;
  }
  
  .phoneInput {
    margin-left: 10px;
    width: 100%;
    padding: 16px;
    gap: 1px;
    border-radius: 16px;
    border: 1px solid var(--Neutral-100, #e4e8ec);
    margin-bottom: 10px;
    font: var(--B1-L);
    letter-spacing: 0.01em;
    text-align: left;
    color: #000;
    outline: none;
  }
  .phoneInput:focus{
    border: 1.5px solid var(--Neutral-500, #323E48);

  }


.pay{
    width: 100%;
    padding: 14px 16px 14px 16px;
    gap: 8px;
    border-radius: 16px;
    background: var(--primary-linear-background);
    font: var(--C1);
    box-shadow: var(--CTA-Sh);
    text-align: center;
    color: #ffffff;
}
.pay:disabled{
    color: var(--Neutral-300, #8B98A4);
    background: var(--Neutral-100, #E4E8EC);
    box-shadow: none !important;
}


.noScroll {
  overflow: hidden;
}


.phoneModal {
  height: auto;
  gap: 24px;
  border-radius: 8px 8px 0 0;
  padding: 0;
  margin: 0;
  background-color: white;
  position: absolute;
  bottom: -100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: slide-in 0.4s forwards;
  padding: 24px 16px;
}

.exit {
  animation: slide-out 0.4s forwards;
}

.exitOverlay{
  animation: slide-out 0.4s forwards;

}

@keyframes slide-in {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
}

@keyframes slide-out {
  from {
    bottom: 0;
    opacity: 1;
  }
  to {
    bottom: -100%;
    opacity: 0;
  }
}