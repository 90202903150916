.help{
    width: 100%;
    gap: 10px
}
.help button{
    padding: 16px 8px 16px 8px;
    gap: 8px;
    border-radius: 16px;
    background: #FFFFFFE5;
    font-size: 19px;
    font-weight: 400;
    line-height: 22.67px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #007AFF;

}